$row-background: #ebe9df;
$head-color: #58542f;

.wa__table th {
  font-size: 20px;
  color: $head-color;
  font-weight: 400;
}

.wa__table > :not(caption) > * > * {
  border-bottom-width: 0;
}

.wa__table td {
  font-size: 18px;
  padding: 15px !important;
}

.wa__table tbody tr {
  background: $row-background;
  vertical-align: middle;
}

.wa__table {
  border-collapse: separate;
  border-spacing: 0 20px;
}

@include media-breakpoint-down(lg) {
  .wa__table th {
    font-size: 15px;
  }

  .wa__table td {
    font-size: 14px;

    h4 {
      font-size: 14px;
    }

    p {
      font-size: 13px;
      margin: 0;
    }
  }
}
