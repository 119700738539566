$btn-gradient-start: #918d5c;
$btn-gradient-end: #58542f;

$btn-success-gradient-start: #7a915c;
$btn-success-gradient-end: #48582f;

$btn-secondary-gradient-start: #4b4c45;
$btn-secondary-gradient-end: #242521;

$btn-cancel-gradient-start: #81201b;
$btn-cancel-gradient-end: #5e1e1c;

$btn-disabled: #797979;

.wa__button {
  font-size: 24px;
  font-weight: 700;

  color: #fff;
  padding: 3px 35px;

  text-transform: uppercase;
  text-align: center;

  background: linear-gradient(180deg,
      $btn-gradient-start 0%,
      $btn-gradient-end 100%);

  transition: 0.1s background ease-in-out;

  &:hover {
    color: #fff; // useful for <a> elements
    background: linear-gradient(180deg,
        $btn-gradient-end 0%,
        $btn-gradient-start 100%);
  }
}

.wa__button.wa__button--secondary {
  background: linear-gradient(180deg,
      $btn-secondary-gradient-start 0%,
      $btn-secondary-gradient-end 100%);

  &:hover {
    background: linear-gradient(180deg,
        $btn-secondary-gradient-end 0%,
        $btn-secondary-gradient-start 100%);
  }
}

.wa__button.wa__button--cancel {
  background: linear-gradient(180deg,
      $btn-cancel-gradient-start 0%,
      $btn-cancel-gradient-end 100%);

  &:hover {
    background: linear-gradient(180deg,
        $btn-cancel-gradient-end 0%,
        $btn-cancel-gradient-start 100%);
  }
}

.wa__button.wa__button--success {
  background: linear-gradient(180deg,
      $btn-success-gradient-start 0%,
      $btn-success-gradient-end 100%);

  &:hover {
    background: linear-gradient(180deg,
        $btn-success-gradient-end 0%,
        $btn-success-gradient-start 100%);
  }
}

.wa__button.wa__button--disabled {
  background: $btn-disabled
}

.full-width {
  width: 100%;
  display: inline-block;
  margin: 1rem 0 0 0;
}

@include media-breakpoint-down(lg) {
  .wa__button {
    font-size: 18px;
  }
}