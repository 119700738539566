//
// pricing CARD
// 
.wa_pricing_cards_div {
  gap: 10px;
  margin-top: 20px;
}

.wa__pricing_card {
  max-width: 320px;
  background: $content-bg;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.wa__pricing_card_header {
  height: 100px;
  font-size: xx-large;
  font-variant: small-caps;
}

.wa__pricing_card_price {
  margin-top: -15px;

}

.wa__pricing_card_price_text {
  color: #3e4497;
  font-size: 36px;
  text-shadow: 0 0 2px #3e4497;
  font-weight: bold;
}

.wa__pricing_card_price_text_small {
  font-size: 12px;
  color: gray;
}

.wa__pricing_card_body {
  min-height: 615px;
}

.wa__pricing_cover_img {
  width: inherit;
  margin-bottom: 10px;
}

.wa__pricing_card_description {
  height: 50px;
  font-size: 14px;
  font-weight: bold;
}

.wa_pricing_card_text {
  font-variant: small-caps;
  gap: 5px;
}

.wa_pricing_card_text_item {
  font-variant: small-caps;
  gap: 5px;
  background: $content-bg;
}

.wa__proceed_button {
  position: absolute;
  left: 0;
  right: 0;
  padding: 10px;
  bottom: 0;
}

.wa__pricing_best_price_sphere {
  position: absolute;
  height: 175px;
  width: 175px;
  background-color: #7a915c;
  border-radius: 50%;
  display: inline-block;
  right: -25%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  bottom: 15%;
}

.wa__pricing_best_price_sphere_style {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  font-size: 38px;
  text-align: center;
  text-shadow: 0 0 8px black;
  font-variant: small-caps;
  color: white;
}

.wa__gameprofile_best_price_sphere {
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: inline-block;
  right: -5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  bottom: 115%;
}

.wa__gameprofile_best_price_sphere_style {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  font-size: 62px;
  text-align: center;
  text-shadow: 0 0 8px black;
  font-variant: small-caps;
  color: white;
}

// 
// pricing SEPARATOR
// 
.wa__pricing_card_separator_line {
  border: 2px solid $btn-gradient-end;
  border-radius: 5px;
  width: 0;
  height: inherit;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 25px;
}

.wa__pricing_card_separator_text {
  color: $btn-gradient-end;
  text-shadow: 0 0 2px $btn-gradient-end;
}