$transition-time: 0.3s;
$on-primary: black;
$primary: #d0cebe;

$very-dark: transparent;
$border-color: #58542f;

$tab-font: "Archivo", sans-serif;

// Changing this every time is annoying so I just made a var
$content-bg: #d0cebe;

.tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.tabs__header {
  flex: 1;
  justify-content: center;
}

.tabs__tab {
  background: transparent;
  color: $on-primary;

  height: 100%;
  padding: 0 3rem 0.5rem 3rem;

  text-align: center;
  text-transform: uppercase;

  font-size: 30px;
  font-family: $tab-font;

  transition: filter $transition-time, border-bottom-color $transition-time,
    background $transition-time;
  cursor: pointer;

  outline: none;
  border: none;

  border-bottom-width: 0.5rem;
  border-bottom-style: solid;
  border-bottom-color: transparent;
}

@include media-breakpoint-down(lg) {
  .tabs__tab {
    padding: 0 1rem 0.5rem 1rem;
    font-size: 20px;
  }
}

.tabs__tab:hover:not(.tabs__tab--current) {
  border-bottom-color: rgba($border-color, 0.4);
  filter: brightness(1.4);
}

.tabs__tab--current {
  border-bottom-color: $border-color;
  font-weight: bold;
}

.tabs__content {
  height: 100%;
  width: 100%;

  background: $content-bg;
  color: $on-primary;

  padding: 100px 1rem;
}

@include media-breakpoint-down(lg) {
  .tabs__tab {
    font-size: 14px;
    border-bottom-width: 2px;
    padding: 0 0rem 0.5rem 0rem;
  }

  .tabs__content {
    padding: 1rem 1rem;
  }
}
