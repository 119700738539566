body {
  color: #242521;
  font-weight: 400;
  font-family: "Archivo", sans-serif;
  background-color: #ebe9df;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: 0 solid;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 15px;
  color: #07171d;
}

li {
  list-style: none;
}

ul {
  margin: 0;
  padding: 0;
}

a,
button,
input,
textarea {
  outline: none !important;
}

.draggable {
  cursor: move;
}

.wa__header {
  height: 82px;
  z-index: 999;
  background-color: #ebe9df;
}

.wa__error_banner {
  color: #dddddd;
  background-color: red;
  border: 2px solid black;
  font-weight: bold;
  font-size: 28px;
}

.wa__footer {
  margin-top: 25px;
  margin-bottom: 10px;
  z-index: 999;
  background-color: #ebe9df;
}

.wa__footer_colored {
  padding: 10px;
  background-color: #d0cebe;
  box-shadow: 0px 0px 5px #d0cebe;
}