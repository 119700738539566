$rules-background: #d0cebe;
$yourgames-item-background: #ebe9df;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// ========== Gameprofile area
.gameprofile__top__bg {
  height: 180px;
}

.gameprofile__top__bg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.gameprofile__body__area {
  margin-top: -100px;
}

.gameprofile__left__wrapper {
  margin-top: -40px;
}

.gamecover__thumb {
  width: auto;
}

.right__side__title h2 {
  font-size: 50px;
  margin-bottom: 70px;
  color: #ffffffe0;
  font-weight: 700;
  width: 180%;
  text-transform: uppercase;
}

.gameprofile__right__thumb {
  margin-top: -15px;
}

.gamecover__thumb img {
  width: 100%;
}

.gameprofile__big__thumb img {
  height: 450px;
}

.mini__thumb {
  margin-top: 2px;

  img {
    width: 100%;
    height: 100%;
  }

  &.mini__thumb--selected img {
    filter: grayscale(50%);
  }
}

@include media-breakpoint-down(lg) {
  .gameprofile__start__button {
    width: 300px;
  }

  .gameprofile__left__wrapper {
    text-align: center;
  }

  .gamecover__thumb {
    text-align: center;
    width: 100%;
  }

  .gamecover__thumb img {
    width: 300px;
  }

  .right__side__title h2 {
    font-size: 40px;
    color: darken(brown, 20%); // TODO: better color
    margin-bottom: 40px;
    margin-top: 20px;
    text-align: center;
  }
}

@include media-breakpoint-down(md) {
  .gameprofile__start__button {
    width: 100%;
  }

  .gamecover__thumb {
    width: 100%;
  }

  .gamecover__thumb img {
    width: 100%;
  }
}

// ========== Rules area
.rules__container {
  background: $rules-background;
}

.single__rule {
  margin-left: 10px;
  border-left: 1px solid rgba(88, 84, 47, 0.3);
}

.single__rule p {
  font-size: 20px;
  margin-bottom: 5px;
}

.single__rule h4 {
  margin-bottom: 0;
  font-size: 30px;
  font-weight: bolder;
}

.rules__container .single__rule:first-child {
  border: 0;
}

@include media-breakpoint-down(lg) {
  .single__rule h4 {
    font-size: 22px;
  }
}

@include media-breakpoint-down(md) {
  .single__rule h4 {
    font-size: 18px;
  }

  .single__rule p {
    font-size: 14px;
  }
}

// ========== User game data
.usergames__table td h4 {
  font-size: 18px;
  margin: 0;
}

.usergames__table td p {
  font-size: 16px;
  margin: 0;
}

.yourgames__title h4 {
  font-size: 24px;
  font-weight: 700;

  text-align: center;
  text-transform: uppercase;
}



.yourgames__list__item {
  padding-top: 50px;
  padding: 12px 15px;

  max-height: 48px;

  align-items: center;

  display: flex;

  background: $yourgames-item-background;
  border-radius: 2px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.yourgames__list__item img {
  min-width: 4%;
  width: 43px;
}

.yourgames__list__item button {
  min-width: 6%;

  margin-right: -20px;
  width: 95px;

  transform: scale(0.7);
}

.yourgames__list__item select {
  width: 30%;
  margin: 0 10px 0 10px;
  background-color: #d0cebe;
  line-height: 1;
}

.yourgames__list__item p {
  width: 30%;
  margin: auto;

  &:not(:first-of-type) {
    text-align: center;
  }

  &:first-of-type {
    margin-left: 10px;
  }
}

.yourgames__list__text>* {
  margin-bottom: 0;
}

.yourgames__list__text h3 {
  font-size: 20px;
  font-weight: 600;
}

.yourgames__list__text p {
  font-size: 16px;
  font-weight: 400;
}

.usergames__empty {
  text-align: center;
  padding: 10px;

  background: $yourgames-item-background;

  font-weight: 600;
  font-size: 24px;
}

.viewgames__show-all {
  text-align: center;

  a {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@include media-breakpoint-down(lg) {
  .yourgames__title {
    margin-top: 25px;
  }

  .usergames__table td {
    h4 {
      font-size: 14px;
    }

    p {
      font-size: 13px;
      margin: 0;
    }
  }

  .yourgames__list__text h3 {
    font-size: 18px;
  }
}

@include media-breakpoint-down(md) {
  .yourgames__list__text h3 {
    font-size: 13px;
  }
}

// ========== Start game modal
.startgame__page-number {
  color: #58542f;
}

// ========== Join Match Area

.form-select {
  text-align: center;
  color: #242521;
  font-size: 20px;
  background: #ebe9df;
}

.select__click__btn {
  flex: 0 0 50px;
  height: 100%;
}

.select__click__btn button {
  height: 100%;
  width: 100%;
  border: 0;
}

.select__click__btn button {
  height: 100%;
  width: 100%;
  border: 0;
  background: linear-gradient(180deg, #918d5c 0%, #58542f 100%);
  position: relative;
}

.select__click__btn button::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-180deg);
  content: "";
  height: 13px;
  width: 20px;
  background: #fff;
  clip-path: polygon(51% 0, 0 100%, 100% 100%);
  transition: 0.3s all ease-in-out;
}

// ========== Sign in/Register
.wa__losin_sign_in_box {
  padding: 15px;
}

.wa__shadowed_box {
  border: 1px solid #58542f;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.register_label {
  margin-bottom: 0;
  font-weight: bold;
}

.wa__error_reason_error {
  background-color: #e6a7a7;
}

.wa__error_reason_warning {
  background-color: #fff3cd;
}

.wa__username_already_taken {
  margin-top: -15px;
  padding-bottom: 15px;
  margin-left: 10px;
  font-size: 12px;
  color: #972036;
}

.wa__profile_avatar {
  width: 150px;
  height: 200px
}

.wa__profile_button {
  transform: scale(0.7);
}

.wa__profile_button_img {
  width: 40px;
}

.wa_collection_box {
  background-color: #d0cebeaa;
}

.wa_collection_div {
  height: 100px;
}

.wa_collection_img {
  width: 200px;
}

.wa__sub_color_Free_Plan {
  background-color: #CD7F3280;
}

.wa__sub_color_Monthly {
  background-color: #C0C0C080;
}

.wa__sub_color_Annual {
  background-color: #FFD70080;
}