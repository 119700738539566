$input-label-color: #58542f;
$input-background: var(--input-background, white);

.wa__label {
  color: $input-label-color;
  font-size: 22px;
  width: 200px;
  display: table-cell;
  vertical-align: middle;
}

.wa__input {
  background: $input-background;
  transition: filter 0.4s;

  &:focus {
    background: $input-background;
    filter: brightness(105%);
  }
}