$svg-background-color: rgb(235, 233, 223);

svg {
    width: 100%;
    height: 100%;
    background-color: $svg-background-color
}

.game_view {
    display: flex;
    width: 100%;
    height: calc(100vh - 82px);
    top: 82px;
}

.svg_area {
    width: 75%;
    margin-top: 48px;
    position: relative;
}

.svg_area_without_chat {
    width: 95%;
    margin-top: 48px;
    position: relative;
}