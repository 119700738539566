$modal-background: #d0cebe;

.waModal .modal-content {
  background: $modal-background;
  padding: 30px 70px;
}

.waModal .modal-title {
  font-weight: bold;
  text-transform: uppercase;
}

.waModal .modal-header {
  font-weight: bold;
  border-bottom: none;
}

.waModal .modal-footer {
  border-top: none;
  justify-content: space-evenly;

  .wa__button {
    font-size: 35px;

    &:not(:first-child) {
      margin-left: 30px;
    }
  }
}

// Sets the background for text inputs inside of modals
.waModal {
  --input-background: #ebe9df;
}